import * as React from 'react';
import { Icon, Snackbar } from '@material-ui/core';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { FileEditor } from './FileEditor';
import Viewer from './Viewer';
import EditorHeader from './EditorHeader';
import FileList from './FileList';
import DragArea from './DragArea';
import KeyBindingsDialog from './KeyBindingsDialog';
import SelectTargetDialog from './SelectTargetDialog';
import EditorActions from '../../../actions/EditorActions';
import {
  isImageFile,
  isTextFile,
  isTexFile,
  isPdfFile,
} from '../../../utils/CommonUtils';
import * as CL2Types from '../../../CL2Types';
import LatexDevModeDialog from './LatexDevModeDialog';
import { withRootTheme } from '../../withRootTheme';
import { PaneSplitter } from './PaneSplitter';
import IconButtonEx from './IconButtonEx';
import { RequireEdit } from './permission/RequireEdit';
import ShareDialog from './ShareDialog';
import { withEditorTheme } from '../../withEditorTheme';
import { InvalidateFileCacheDialog } from './InvalidateFileCacheDialog';

interface Props {
  compileDisable: boolean;
  compiling: boolean;
  conflictFileDialog: boolean;
  conflictFiles: Array<CL2Types.ConflictFile>;
  currentFile: CL2Types.CurrentFile;
  compileTargetFile: CL2Types.ProjectFile;
  error: CL2Types.EditorError;
  fileLoading: boolean;
  loading: boolean;
  onEditorChange: (newContent: string) => void;
  onCloseSnackbar: () => void;
  openSnackbar: boolean;
  pdfPageInfo: CL2Types.PdfPageInfo;
  editorPositionInfo: CL2Types.EditorPositionInfo;
  showSynctexPdfIndicator: boolean;
  showSynctexEditorIndicator: boolean;
  previewFile: CL2Types.PreviewFile;
  project: CL2Types.EditorProject;
  result: CL2Types.Result;
  texDocSearching: boolean;
  documentResult: CL2Types.DocumentResult;
  saveAndCompileHandler: () => void;
  killCompileHandler: () => void;
  saveDisable: boolean;
  saveHandler: () => void;
  snackbarMessage: string;
  suspendedRequests: Array<CL2Types.Request>;
  syncHandler: (storageType: number) => void;
  syncing: boolean;
  unsyncHandler: (storageType: number) => void;
  updateProjectHandler: (param: object) => void; // TODO: use nallower type
  openShareDialog: (open: boolean) => void;
  upload: CL2Types.UploadStatuses;
  user: CL2Types.EditorUser;
  waiting: boolean;
  network: CL2Types.NetworkStatus;
  restoreDialog: CL2Types.RestoreDialog;
  files?: Array<CL2Types.ProjectFile>;
  onBeforeOpenFile: () => void;
  tabValue: number;
  bibtexItems: CL2Types.BibtexItems;
  shareDialog: { open: boolean };
  createShare: () => void;
  destroyShare: () => void;
  showPdfViewLoading: boolean;
  closeSelectTargetDialog: () => void;
  selectTargetDialog: {
    open: boolean;
    files: CL2Types.ProjectFile[];
  };
  invalidateFileCacheHandler: () => void;
  openInvalidateFileCacheDialog: (open: boolean) => void;
  invalidateFileCacheDialog: {
    open: boolean;
    disableButton?: boolean;
  };
  successInvalidateFileCacheDialog: {
    open: boolean;
  };
  closeSuccessInvalidateFileCacheDialog: () => void;
}
interface State {
  keybindingsDialogOpend: boolean;
  developmentModeDialogOpened: boolean;
}

class EditorApp extends React.Component<Props, State> {
  private editorRef: React.RefObject<FileEditor> =
    React.createRef<FileEditor>();

  constructor(props) {
    super(props);
    this.state = {
      keybindingsDialogOpend: false,
      developmentModeDialogOpened: false,
    };
  }

  componentDidMount() {
    EditorActions.autoPollingExportStatus(5000);
  }

  _fileCompareFunc(a, b) {
    if (a.is_folder === b.is_folder) {
      return a.name.localeCompare(b.name);
    }
    return b.is_folder ? 1 : -1;
  }

  onKeyPress(event) {
    // Ctrl (Command) 押下
    if (
      (event.ctrlKey && !event.metaKey) ||
      (!event.ctrlKey && event.metaKey)
    ) {
      let keyCode;

      if (event.keyCode) {
        keyCode = event.keyCode;
      } else {
        keyCode = event.which;
      }

      // Ctrl+S (Command+S) 押下
      if (keyCode === 83) {
        event.preventDefault();
        this.props.saveHandler();
      }
    }
  }

  render() {
    const orderedFiles: Dictionary<CL2Types.ProjectFile[]> = _.groupBy(
      this.props.files,
      (file) => {
        if (file.belonging_to) {
          return file.belonging_to;
        }
        return 0;
      },
    );
    orderedFiles[0] = orderedFiles[0] || [];

    for (const index in orderedFiles) {
      orderedFiles[index].sort((a, b) => this._fileCompareFunc(a, b));
    }

    const mainStyle: React.CSSProperties = {
      position: 'absolute',
      top: '51px',
      bottom: '0px',
      left: '0px',
      right: '0px',
    };
    const graphicsFiles: Array<CL2Types.ProjectFile> = _.flatMap(
      this.props.files,
      (files) => files,
    ).filter((file) => isImageFile(file.mimetype) || isPdfFile(file.mimetype));
    const textFiles: Array<CL2Types.ProjectFile> = _.flatMap(
      this.props.files,
      (files) => files,
    ).filter((file) => isTextFile(file.mimetype));
    const texFiles: Array<CL2Types.ProjectFile> = _.flatMap(
      this.props.files,
      (files) => files,
    ).filter((file) => isTexFile(file.mimetype));

    return (
      <div
        style={{ position: 'relative' }}
        onKeyDown={this.onKeyPress.bind(this)}
      >
        <EditorHeader
          syncHandler={this.props.syncHandler}
          unsyncHandler={this.props.unsyncHandler}
          saveHandler={this.props.saveHandler}
          saveAndCompileHandler={this.props.saveAndCompileHandler}
          killCompileHandler={this.props.killCompileHandler}
          syncing={this.props.syncing}
          saveDisable={this.props.saveDisable}
          compileDisable={this.props.compileDisable}
          updateProjectHandler={this.props.updateProjectHandler}
          result={this.props.result}
          project={this.props.project}
          user={this.props.user}
          waiting={this.props.waiting}
          error={this.props.error}
          compiling={this.props.compiling}
          network={this.props.network}
          openKeyBindingsDialog={() => this.setKeyBindingsDialog(true)}
          openDevelopmentModeDialog={() => this.setDevelopmentModeDialog(true)}
          openShareDialog={this.props.openShareDialog}
          shares={this.props.project?.shares}
        />
        <div style={mainStyle}>
          <PaneSplitter
            initialWidth={[300, -1, 600]}
            renderExpandButton={(right, onClick, styles) => (
              <IconButtonEx
                style={{
                  width: '54px',
                  height: '100%',
                  borderRadius: 'unset',
                  ...styles,
                }}
                onClick={onClick}
                children={
                  right ? (
                    <Icon style={{ fontSize: '20px' }}>chevron_right</Icon>
                  ) : (
                    <Icon style={{ fontSize: '20px' }}>chevron_left</Icon>
                  )
                }
                disableRipple
                disabled={false}
                key="1"
              />
            )}
          >
            <FileList
              waiting={this.props.waiting}
              loading={this.props.loading}
              project={this.props.project}
              previewFile={this.props.previewFile}
              files={orderedFiles}
              currentFile={this.props.currentFile}
              restoreDialog={this.props.restoreDialog}
              onBeforeOpenFile={this.props.onBeforeOpenFile}
            />
            <FileEditor
              style={{
                display: 'inline-block',
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
              }}
              currentFile={this.props.currentFile}
              waiting={this.props.waiting}
              loading={this.props.fileLoading}
              projectLoading={this.props.loading}
              onChange={this.props.onEditorChange}
              saveAndCompileHandler={this.props.saveAndCompileHandler}
              isTargetFile={
                this.props.currentFile &&
                this.props.project &&
                this.props.currentFile.id ===
                  this.props.project.compile_target_file_id
              }
              ref={this.editorRef}
              errors={this.props.result.errors}
              timestamp={this.props.result.timestamp}
              conflictFileDialog={this.props.conflictFileDialog}
              conflictFiles={this.props.conflictFiles}
              graphicsFiles={graphicsFiles}
              textFiles={textFiles}
              texFiles={texFiles}
              compileTargetFile={this.props.compileTargetFile}
              suspendedRequests={this.props.suspendedRequests}
              project={this.props.project}
              editorPositionInfo={this.props.editorPositionInfo}
              showSynctexEditorIndicator={this.props.showSynctexEditorIndicator}
              bibtexItems={this.props.bibtexItems}
            />
            <Viewer
              compiling={this.props.compiling}
              result={this.props.result}
              texDocSearching={this.props.texDocSearching}
              documentResult={this.props.documentResult}
              pdfPageInfo={this.props.pdfPageInfo}
              showSynctexPdfIndicator={this.props.showSynctexPdfIndicator}
              scrollSync={this.props.project && this.props.project.scroll_sync}
              tabValue={this.props.tabValue}
              project={this.props.project}
              showPdfViewLoading={this.props.showPdfViewLoading}
              invalidateFileCacheHandler={this.props.invalidateFileCacheHandler}
              openInvalidateFileCacheDialog={
                this.props.openInvalidateFileCacheDialog
              }
            />
          </PaneSplitter>
        </div>
        <RequireEdit>
          <DragArea projectFiles={orderedFiles} upload={this.props.upload} />
        </RequireEdit>
        <SelectTargetDialog
          projectId={(this.props.project || { id: undefined }).id}
          loading={this.props.loading}
          onClose={this.props.closeSelectTargetDialog}
          {...this.props.selectTargetDialog}
        />
        <Snackbar
          message={
            <span style={{ fontSize: '14px' }}>
              {this.props.snackbarMessage}
            </span>
          }
          action="OK"
          onClick={this.props.onCloseSnackbar}
          autoHideDuration={5000}
          open={this.props.openSnackbar}
          onClose={this.props.onCloseSnackbar}
        />
        <KeyBindingsDialog
          open={this.state.keybindingsDialogOpend}
          onClose={() => this.setKeyBindingsDialog(false)}
        />
        <LatexDevModeDialog
          open={this.state.developmentModeDialogOpened}
          onClose={() => this.setDevelopmentModeDialog(false)}
        />
        <ShareDialog
          open={this.props.shareDialog.open}
          shares={this.props.project?.shares}
          createShare={this.props.createShare}
          destroyShare={this.props.destroyShare}
          onClose={() => {
            this.props.openShareDialog(false);
          }}
        />
        <InvalidateFileCacheDialog
          open={this.props.invalidateFileCacheDialog.open}
          onClose={() => this.props.openInvalidateFileCacheDialog(false)}
          disableButton={this.props.invalidateFileCacheDialog.disableButton}
          successOpen={this.props.successInvalidateFileCacheDialog.open}
          onSuccessClose={this.props.closeSuccessInvalidateFileCacheDialog}
          invalidateFileCacheHandler={this.props.invalidateFileCacheHandler}
        />
      </div>
    );
  }

  setKeyBindingsDialog(opend = true) {
    this.setState({
      keybindingsDialogOpend: opend,
    });
  }

  setDevelopmentModeDialog(opened = true) {
    this.setState({
      developmentModeDialogOpened: opened,
    });
  }
}

export default withRootTheme(withEditorTheme(EditorApp));
