import { Component } from 'react';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import EditorApp from '../components/projects/edit/EditorApp';
import EditorActions from '../actions/EditorActions';
import { getCurrentFile, getFileById } from '../reducers/editor';
import * as CL2Types from '../CL2Types';
import { withErrorBoundaryFallback } from '../components/error/withErrorBoundaryFallback';

interface Props {
  user: CL2Types.EditorUser;
  files: CL2Types.ProjectFile[];
  exist_last_opened_file: boolean;
  currentFile: CL2Types.CurrentFile;
  compileTargetFile: CL2Types.ProjectFile;
  compiling: boolean;
  waiting: boolean;
  project: CL2Types.EditorProject;
  conflictFileDialog: boolean;
  conflictFiles: Array<CL2Types.ConflictFile>;
  fileLoading: boolean;
  openSnackbar: boolean;
  loading: boolean;
  pdfPageInfo: CL2Types.PdfPageInfo;
  editorPositionInfo: CL2Types.EditorPositionInfo;
  showSynctexPdfIndicator: boolean;
  showSynctexEditorIndicator: boolean;
  snackbarMessage: string;
  suspendedRequests: Array<CL2Types.Request>;
  syncing: boolean;
  upload: CL2Types.UploadStatuses;
  restoreDialog: CL2Types.RestoreDialog;
  network: CL2Types.NetworkStatus;
  result: CL2Types.Result;
  texDocSearching: boolean;
  documentResult: CL2Types.DocumentResult;
  previewFile: CL2Types.PreviewFile;
  error: CL2Types.EditorError;
  tabValue: number;
  bibtexItems: CL2Types.BibtexItems;
  selectTargetDialog: { open: boolean; files: CL2Types.ProjectFile[] };
}
interface State {
  syncDisable: boolean;
  saveDisable: boolean;
  compileDisable: boolean;
}
class EditorAppContainer extends Component<Props, State> {
  /*
   * private vars
   */

  // キー入力位置1文字ごとに頻繁に書き換える変数をstateに入れると処理が重くなるのでprivate var 扱い
  private editorContent = '';

  private cancelDelayedSaveAndCompile: () => void = function () {
    let cancelHandler: () => void;
    while (this.cancelDelayedSaveAndCompileHandles.length > 0) {
      cancelHandler = this.cancelDelayedSaveAndCompileHandles.pop();
      cancelHandler();
    }
  };

  private cancelDelayedSaveAndCompileHandles: Array<() => void> = [];

  /*
   * methods
   */

  private runSaveAndCompile = (file, isAutoCompile) => {
    if (!this.props.project?.permission.edit) {
      return;
    }
    this.handleSave(file).then(
      () => {
        if (
          !isAutoCompile ||
          (this.editorContent !== '' &&
            this.props.project &&
            this.props.project.auto_compile)
        ) {
          this.runCompile();
        }
      },
      () => {
        // handle error
        this._saveAndCompile(file, isAutoCompile);
      },
    );
  };

  private _saveAndCompile = (() => {
    const delayedSaveAndCompile = _.debounce(this.runSaveAndCompile, 2000);
    this.cancelDelayedSaveAndCompileHandles.push(delayedSaveAndCompile.cancel);
    return (file, isAutoCompile) => {
      delayedSaveAndCompile(file, isAutoCompile);
    };
  })();

  private handleSave = (file) => {
    if (!this.props.project?.permission.edit) {
      return;
    }
    if (this.props.waiting) {
      // save 中はすぐに新しいsaveの実行をしない
      return Promise.reject();
    }
    if (this.state.saveDisable) {
      // 変更がないのでsave必要なし
      return Promise.resolve();
    }
    this.setState({
      saveDisable: true,
    });
    return EditorActions.updateFile(file.id, { content: this.editorContent });
  };

  private runCompile = () => {
    if (this.props.project && this.props.project.compile_target_file_id) {
      EditorActions.compile();
    }
  };

  // misc handlers

  private onEditorChange = (newContent) => {
    if (!this.props.currentFile) {
      return;
    }
    this.editorContent = newContent;
    if (this.state.saveDisable) {
      this.setState({
        saveDisable: false,
      });
    }
    this._saveAndCompile(this.props.currentFile, true);
  };

  private onBeforeOpenFile = () => {
    this.cancelDelayedSaveAndCompile();

    // immediately save
    if (!this.state.saveDisable) {
      this.runSaveAndCompile(this.props.currentFile, true);
    }
  };

  private handleSync = (storageType) => {
    this.setState({
      syncDisable: true,
      saveDisable: true,
      compileDisable: true,
    });
    EditorActions.exportProject(storageType, 5000);
  };

  private handleUnsync = () => {
    this.setState({
      syncDisable: true,
      saveDisable: true,
      compileDisable: true,
    });
    EditorActions.unsyncProject();
  };

  private handleSaveAndCompile = (file) => {
    if (!this.props.project.permission.edit) {
      return;
    }
    if (this.state.saveDisable) {
      // saveが抑止されている状態の時は、単にコンパイルするだけでよい
      EditorActions.compile();
    } else {
      this.runSaveAndCompile(file, false);
    }
  };

  private handleKillCompile = () => {
    EditorActions.killCompileProcess();
  };

  private handleUpdateProject = async (params) => {
    if (this.props.project) {
      await EditorActions.updateProject(params);
    }
  };

  private openShareDialog = (open: boolean) => {
    EditorActions.openShareDialog(open);
  };

  private createShare = () => {
    EditorActions.createShare();
  };

  private destroyShare = (token: string) => {
    EditorActions.updateShare(token, { authority: 0 });
  };

  private _isCompilableFile(file) {
    // application/x-rtex はRtexのために便宜的に設けたmimetype
    return (
      file.belonging_to === null &&
      ((file.mimetype === 'application/x-tex' && /\.tex$/.test(file.name)) ||
        (file.mimetype === 'application/x-rtex' && /\.Rtex$/.test(file.name)) ||
        (file.mimetype === 'application/x-latex' && /\.ltx$/.test(file.name)) ||
        (file.mimetype === 'text/markdown' && /\.md$/.test(file.name)))
    );
  }

  private mayOpenSelectTargetDialog = () => {
    if (
      this.props.loading ||
      this.props.selectTargetDialog.open ||
      this.props.compileTargetFile
    ) {
      return;
    }
    const compilableFiles = this.props.files.filter(this._isCompilableFile);
    if (compilableFiles.length === 0) {
      return;
    }

    EditorActions.openSelectTargetDialog(compilableFiles);
  };

  private closeSelectTargetDialog = () => {
    EditorActions.closeSelectTargetDialog();
  };

  private invalidateFileCacheHandler = () => {
    EditorActions.invalidateFileCache();
  };

  private openInvalidateFileCacheDialog = (open: boolean) => {
    EditorActions.openInvalidateFileCacheDialog(open);
  };

  private closeSuccessInvalidateFileCacheDialog = () => {
    EditorActions.closeSuccessInvalidateFileCacheDialog();
  };

  /*
   * React component handlers
   */

  constructor(props) {
    super(props);
    this.state = {
      syncDisable: false,
      saveDisable: true,
      compileDisable: true,
    };
  }

  render() {
    return (
      <EditorApp
        onEditorChange={this.onEditorChange}
        onBeforeOpenFile={this.onBeforeOpenFile}
        syncHandler={this.handleSync}
        unsyncHandler={this.handleUnsync}
        saveHandler={() => {
          this.handleSave(this.props.currentFile);
        }}
        saveAndCompileHandler={() => {
          this.handleSaveAndCompile(this.props.currentFile);
        }}
        killCompileHandler={this.handleKillCompile}
        updateProjectHandler={this.handleUpdateProject}
        onCloseSnackbar={EditorActions.closeSnackbar}
        openShareDialog={this.openShareDialog}
        closeSelectTargetDialog={this.closeSelectTargetDialog}
        createShare={this.createShare}
        destroyShare={this.destroyShare}
        invalidateFileCacheHandler={this.invalidateFileCacheHandler}
        openInvalidateFileCacheDialog={this.openInvalidateFileCacheDialog}
        closeSuccessInvalidateFileCacheDialog={
          this.closeSuccessInvalidateFileCacheDialog
        }
        {...this.props}
        {...this.state}
      />
    );
  }

  componentDidMount() {
    // network event
    window.addEventListener('offline', (e) => {
      EditorActions.onOffline();
    });

    window.addEventListener('online', (e) => {
      EditorActions.onOnline();
    });

    EditorActions.loadUser();
    EditorActions.loadProjectInfo().then(() => {
      // Must wait for permission info
      EditorActions.loadFiles()
        .then(() => {
          if (this.editorContent !== '') {
            this.runCompile();
          }
        })
        .then(() => {
          // Open last opened file
          if (this.props.project?.last_opened_file_id) {
            EditorActions.openFile(this.props.project?.last_opened_file_id);
          } else {
            EditorActions.openFile(this.props.project?.compile_target_file_id);
          }
        });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.currentFile ||
      !this.props.currentFile ||
      nextProps.currentFile.id !== this.props.currentFile.id
    ) {
      this.setState({
        syncDisable:
          nextProps.syncing ||
          (nextProps.project &&
            nextProps.project.sync_target === 'DropboxSync'),
        compileDisable:
          nextProps.syncing ||
          !(nextProps.project && nextProps.project.compile_target_file_id),
        saveDisable: true,
      });
    } else {
      this.setState({
        syncDisable:
          nextProps.syncing ||
          (nextProps.project &&
            nextProps.project.sync_target === 'DropboxSync'),
        compileDisable:
          nextProps.syncing ||
          !(nextProps.project && nextProps.project.compile_target_file_id),
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (!prevProps.project && this.props.project) {
      EditorActions.fetchCompileResult();
    }
    // When anything changed except for select target dialog state
    if (prevProps.selectTargetDialog === this.props.selectTargetDialog) {
      this.mayOpenSelectTargetDialog();
    }
  }
}

const mapStateToProps = (state) => {
  const currentFile = getCurrentFile(state.editor);
  const compileTargetFile =
    state.editor.files.length &&
    state.editor.project?.compile_target_file_id &&
    getFileById(state.editor, state.editor.project?.compile_target_file_id);

  return {
    ...state.editor,
    currentFile,
    compileTargetFile,
  };
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundaryFallback(EditorAppContainer));
