import { createSlice } from '@reduxjs/toolkit';
import {
  autoWidth,
  expandOrShrinkToFitScreen,
  findAutoWidthPane,
  setWidthAndAdjustRight,
} from './widthArray';

const initialState = {
  widthArray: [],
  movingWidthArray: [],
};

const paneSplitterSlice = createSlice({
  name: 'paneSplitter',
  initialState,
  reducers: {
    fitContent(state, action) {
      const newState = { ...state };
      let newArray = state.widthArray.length
        ? state.widthArray
        : action.payload.initialWidth;
      newArray = autoWidth(newArray, action.payload.screenWidth);
      newArray = expandOrShrinkToFitScreen(
        newArray,
        action.payload.screenWidth,
        findAutoWidthPane(action.payload.initialWidth),
      );
      newState.widthArray = newArray;
      return newState;
    },
    mouseMove(state, action) {
      const gap = action.payload.x - action.payload.startX;

      const width = Math.min(
        Math.max(0, state.widthArray[action.payload.index] + gap),
        state.widthArray[action.payload.index] +
          state.widthArray[action.payload.index + 1],
      );

      return {
        ...state,
        movingWidthArray: setWidthAndAdjustRight(
          state.widthArray,
          action.payload.index,
          width,
        ),
      };
    },
    mouseUp(state) {
      return {
        ...state,
        movingWidthArray: [],
        widthArray: state.movingWidthArray,
      };
    },
    setWidthAndAdjustRight(state, action) {
      return {
        ...state,
        widthArray: setWidthAndAdjustRight(
          state.widthArray,
          action.payload.index,
          action.payload.width,
        ),
      };
    },
    setWidthAndAdjustLeft(state, action) {
      const width =
        state.widthArray[action.payload.index - 1] +
        state.widthArray[action.payload.index] -
        action.payload.width;
      return {
        ...state,
        widthArray: setWidthAndAdjustRight(
          state.widthArray,
          action.payload.index - 1,
          width,
        ),
      };
    },
  },
});

export const paneSplitterActions = paneSplitterSlice.actions;

export type State = typeof initialState;
export default paneSplitterSlice.reducer;
