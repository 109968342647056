import {
  bracketMatchingHandle,
  foldInside,
  foldNodeProp,
  indentNodeProp,
  LanguageSupport,
  LRLanguage,
} from '@codemirror/language';
import { styleTags, tags as t } from '@lezer/highlight';
import { keymap } from '@codemirror/view';
import { parser } from './parser';
import {
  autoCloseMathBrackets,
  closeMathBracketsKeymap,
} from '../closeMathBrackets';

export const stexLanguage = LRLanguage.define({
  parser: parser.configure({
    props: [
      foldNodeProp.add({
        Block: foldInside,
        CommentBlock: foldInside,
        Section: function foldAfter(node) {
          const first = node.firstChild;
          const last = node.lastChild;
          return first && last && first.to < (last?.from ?? 0)
            ? { from: first.to, to: last?.type.isError ? node.to : last.to }
            : null;
        },
      }),
      indentNodeProp.add({
        Block: (context) => null,
        CommentBlock: (context) => null,
        Section: (context) => null,
      }),
      styleTags({
        TagName: t.tagName,
        Atom: t.atom,
        String: t.string,
        ModuleName: t.standard(t.variableName),
        Keyword: t.keyword,
        MathNumber: t.number,
        MathVariable: t.special(t.variableName),
        LineComment: t.lineComment,
        CommentContent: t.blockComment,
        '{ } [ ]': t.bracket,
      }),
      bracketMatchingHandle.add({
        TagBegin: (node) => {
          if (
            node.parent.lastChild.name !== 'TagEnd' &&
            node.parent.lastChild.name !== 'MismatchedTagEnd'
          ) {
            return null;
          }
          return node;
        },
      }),
    ],
  }),
  languageData: {
    name: 'stex',
    commentTokens: { line: '%' },
    closeBrackets: {
      before: ')]}:;>$',
    },
  },
});

export function stex() {
  return new LanguageSupport(stexLanguage, [
    autoCloseMathBrackets,
    keymap.of(closeMathBracketsKeymap),
  ]);
}
