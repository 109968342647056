import * as React from 'react';
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react/store';
import { paneSplitterActions } from 'react/features/paneSplitter/paneSplitterSlice';
import _ from 'lodash';

const PaneSplitterContext = React.createContext({
  leftButton: null,
  rightButton: null,
});

const useStyles = makeStyles<Theme, Partial<Props>>((theme) => ({
  root: {
    display: 'grid',
    'grid-auto-columns': 'max-content',
    height: '100%',
    overflow: 'hidden',
  },
  pane: {
    background: theme.palette.background.pane,
    'grid-row': '1 / 1',
    overflow: 'hidden',
  },
  divider: {
    background: theme.palette.background.paneDivider,
    cursor: 'col-resize',
    width: ({ dividerWidth }) => dividerWidth,
    'grid-row': '1 / 1',
    '&:hover': {
      background: theme.palette.background.paneDividerHover,
    },
    position: 'relative',
  },
  dividerInner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: -5,
    padding: '0 5px',
    boxSizing: 'content-box',
    zIndex: 10,
  },
}));

interface Props extends React.HTMLAttributes<{}> {
  children?: ReactNode;
  dividerWidth?: number;
  initialWidth: number[];
  renderExpandButton: (
    right: boolean,
    onClick: () => void,
    styles?: CSSProperties,
  ) => JSX.Element;
}

function isMouseEvent(e): e is React.MouseEvent | MouseEvent {
  return !(e as TouchEvent).touches;
}

function PaneSplitter({
  children,
  dividerWidth = 6,
  initialWidth,
  renderExpandButton,
  ...rest
}: Props) {
  const adjustedInitialWidth = useRef<number[] | undefined>();
  const widthArray = useSelector((state) => state.paneSplitter.widthArray);
  const movingWidthArray = useSelector(
    (state) => state.paneSplitter.movingWidthArray,
  );
  const mouseDownX = useRef([-1, -1]);

  const classes = useStyles({ dividerWidth });
  const paneRef = useRef<HTMLDivElement | undefined>();
  const dispatch = useDispatch();

  const fitContent = useCallback(() => {
    if (!paneRef.current) {
      return;
    }
    const screenWidth =
      paneRef.current.clientWidth - (initialWidth.length - 1) * dividerWidth;
    adjustedInitialWidth.current = initialWidth.map((width) =>
      Math.min(width, screenWidth / initialWidth.length),
    );

    dispatch(
      paneSplitterActions.fitContent({
        screenWidth,
        initialWidth: adjustedInitialWidth.current,
      }),
    );
  }, [widthArray, dividerWidth, initialWidth]);

  useEffect(() => {
    window.addEventListener('resize', fitContent);
    return () => {
      window.removeEventListener('resize', fitContent);
    };
  }, [fitContent]);
  useLayoutEffect(fitContent, []);

  const onMouseDown = (index) => (e: React.MouseEvent | React.TouchEvent) => {
    if (isMouseEvent(e) && e.button !== 0) {
      return;
    }
    if (isMouseEvent(e)) {
      e.preventDefault();
    }
    const x = isMouseEvent(e) ? e.clientX : e.touches[0].clientX;

    mouseDownX.current = [index, x];
  };

  const onMouseMoveDispatch = useCallback(
    _.throttle((payload) => {
      dispatch(paneSplitterActions.mouseMove(payload));
    }, 10),
    [],
  );

  const onMouseMove = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const [index, startX] = mouseDownX.current;
      if (index < 0) {
        return;
      }
      if (!isMouseEvent(e) && e.touches.length > 1) {
        return;
      }
      e.preventDefault();
      const x = isMouseEvent(e) ? e.clientX : e.touches[0].clientX;

      onMouseMoveDispatch({ x, index, startX });
    },
    [widthArray],
  );

  const onMouseUp = useCallback(
    (e) => {
      mouseDownX.current = [-1, -1];

      if (movingWidthArray.length) {
        if (isMouseEvent(e)) {
          e.preventDefault();
        }
        dispatch(paneSplitterActions.mouseUp());
      }
    },
    [movingWidthArray],
  );

  useEffect(() => {
    'mouseup touchend'.split(' ').forEach((type) => {
      window.addEventListener(type, onMouseUp);
    });
    return () => {
      'mouseup touchend'.split(' ').forEach((type) => {
        window.removeEventListener(type, onMouseUp);
      });
    };
  }, [onMouseUp]);

  useEffect(() => {
    'mousemove touchmove'.split(' ').forEach((type) => {
      window.addEventListener(type, onMouseMove, { passive: false });
    });
    return () => {
      'mousemove touchmove'.split(' ').forEach((type) => {
        window.removeEventListener(type, onMouseMove);
      });
    };
  }, [onMouseMove]);

  const makeContextValue = (index: number) => {
    let leftButton;
    if (index <= 0) {
      leftButton = null;
    } else {
      const isLeftButtonExpanding = widthArray[index - 1] > 1;
      const onClickLeft = () => {
        if (isLeftButtonExpanding) {
          dispatch(
            paneSplitterActions.setWidthAndAdjustRight({
              width: 0,
              index: index - 1,
            }),
          );
        } else {
          const isAuto = initialWidth[index - 1] < 0;
          const initialLeftWidth = adjustedInitialWidth.current[index - 1];
          dispatch(
            paneSplitterActions.setWidthAndAdjustRight({
              width: isAuto ? widthArray[index] : initialLeftWidth,
              index: index - 1,
            }),
          );
        }
      };
      leftButton = renderExpandButton(!isLeftButtonExpanding, onClickLeft);
    }

    let rightButton;
    if (index >= React.Children.count(children) - 1) {
      rightButton = null;
    } else {
      const isRightButtonExpanding = widthArray[index + 1] > 1;
      const onClickRight = () => {
        if (isRightButtonExpanding) {
          dispatch(
            paneSplitterActions.setWidthAndAdjustLeft({
              width: 0,
              index: index + 1,
            }),
          );
        } else {
          const isAuto = initialWidth[index + 1] < 0;
          const initialRightWidth = adjustedInitialWidth.current[index + 1];
          dispatch(
            paneSplitterActions.setWidthAndAdjustLeft({
              width: isAuto ? widthArray[index] : initialRightWidth,
              index: index + 1,
            }),
          );
        }
      };
      const styles: CSSProperties = { float: 'right' };
      rightButton = renderExpandButton(
        isRightButtonExpanding,
        onClickRight,
        styles,
      );
    }

    return {
      leftButton,
      rightButton,
    };
  };

  const splitChildren = [];
  React.Children.forEach(children, (child, index) => {
    splitChildren.push(
      // eslint-disable-next-line react/no-array-index-key
      <div className={classes.divider} key={`div-${index}`}>
        <div
          className={classes.dividerInner}
          onMouseDown={onMouseDown(index - 1)}
          onTouchStart={onMouseDown(index - 1)}
        />
      </div>,
    );
    splitChildren.push(
      <PaneSplitterContext.Provider
        value={makeContextValue(index)}
        // eslint-disable-next-line react/no-array-index-key
        key={`pane-${index}`}
      >
        <div
          className={classes.pane}
          style={{ width: `${movingWidthArray[index] ?? widthArray[index]}px` }}
        >
          {child}
        </div>
      </PaneSplitterContext.Provider>,
    );
  });
  return (
    <div className={classes.root} ref={paneRef} {...rest}>
      {splitChildren.slice(1)}
    </div>
  );
}

export { PaneSplitter, PaneSplitterContext };
