import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import { configureStore, isImmutableDefault } from '@reduxjs/toolkit';
import editorReducer, { State as EditorState } from '../reducers/editor';
import paneSplitterReducer, {
  State as PaneSplitterState,
} from '../features/paneSplitter/paneSplitterSlice';

// https://github.com/BBB/dropzone-redux-form-example/issues/5#issuecomment-1083565361
const isImmutable = (value) =>
  isImmutableDefault(value) || value instanceof File;
export const useSelector: TypedUseSelectorHook<{
  editor: EditorState;
  paneSplitter: PaneSplitterState;
}> = useReduxSelector;

const editorStore = configureStore({
  reducer: {
    editor: editorReducer,
    paneSplitter: paneSplitterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {
        isImmutable,
      },
    }),
});

export { editorStore };
