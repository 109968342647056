$(() => {
  const $video = $('.home.index video');
  const videoButtons = $('.home.index .video_button');
  videoButtons.on('click', function () {
    const $this = $(this);
    videoButtons.removeClass('selected');
    $this.addClass('selected');
    $video.children().remove();
    const name = $this.attr('name');
    $.each(['.webm', '.mp4', '.m4v'], (i, ext) => {
      $video.append($(`<source src="/videos/${name}${ext}">`));
    });
    (<HTMLVideoElement>$video[0]).load();
  });
});
