import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Icon,
  IconButton,
  OutlinedInput,
} from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from '@material-ui/core/styles';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { t } from '../i18n';
import UserStore from '../stores/UserStore';
import UserActions from '../actions/UserActions';

interface Props {
  open: boolean;
  token?: string;
  client?: string;
  errors: string[];
  successed_to_generate: boolean;
  failed_to_generate: boolean;
  successed_to_delete: boolean;
  failed_to_delete: boolean;
  generateToken: (password: string) => void;
  deleteTokens: () => void;
  onClose: () => void;
}

interface State {
  password: string;
  password_set: boolean;
  is_client_copied: boolean;
  is_token_copied: boolean;
}

interface CopyableTextProps {
  text: string | null;
  onCopy?: () => void;
}

const OutlinedInputEx = withStyles({
  root: { width: '300px', height: '24px', paddingRight: '0px' },
})(OutlinedInput);
class CopyableText extends React.Component<CopyableTextProps, {}> {
  render() {
    return (
      <OutlinedInputEx
        type="text"
        value={this.props.text || ''}
        readOnly
        endAdornment={
          <CopyToClipBoard
            onCopy={this.props.onCopy}
            text={this.props.text || ''}
          >
            <IconButton
              disabled={!this.props.text}
              size="small"
              children={
                <Icon style={{ fontWeight: 400, fontSize: '20px' }}>
                  assignment
                </Icon>
              }
            />
          </CopyToClipBoard>
        }
      />
    );
  }
}

class ExtensionDialog extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      password_set: UserStore.getStates().password_set,
      is_client_copied: false,
      is_token_copied: false,
    };
  }

  componentDidMount() {
    UserStore.addChangeListener(this.onChange);
    UserActions.loadUserInfo();
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onChange);
  }

  private generateBtnEnabled = () => !this.props.token && this.state.password;

  private generateToken = () => {
    this.props.generateToken(this.state.password);
  };

  private passwordDialogEnabled = () => !this.props.client || !this.props.token;

  private onChange = () => {
    this.setState({ password_set: UserStore.getStates().password_set });
  };

  private onCloseDialog = () => {
    if (
      this.passwordDialogEnabled() ||
      (this.state.is_client_copied && this.state.is_token_copied)
    ) {
      this.onClickCancel();
    }
  };

  private onClickCancel = () => {
    this.resetPassword();
    this.props.onClose();
  };

  private onDeleteTokens = () => {
    this.resetPassword();
    this.props.deleteTokens();
  };

  private resetPassword = () => {
    this.setState({ password: '' });
  };

  private renderExplanation = () => {
    let explanations;

    if (!this.passwordDialogEnabled()) {
      explanations = (
        <div>{t('view:extension.show_token_dialog.explanation')}</div>
      );
    } else if (this.state.password_set) {
      explanations = (
        <div>{t('view:extension.password_dialog.explanation')}</div>
      );
    } else {
      explanations = [...Array(3)].map((_, i) => (
        <div key={`explanation${i + 1}`}>
          {t(`view:extension.set_password_dialog.explanation${i + 1}`)}
        </div>
      ));
    }

    return explanations;
  };

  private renderAttentions = () => {
    const attentionsStyle: React.CSSProperties = {
      marginTop: '10px',
      fontSize: '11px',
    };
    let attentions;

    if (!this.passwordDialogEnabled()) {
      attentions = [...Array(2)].map((_, i) => (
        <div key={`attension${i + 1}`}>
          *{t(`view:extension.show_token_dialog.attension${i + 1}`)}
        </div>
      ));
    }

    if (attentions) {
      return <div style={attentionsStyle}>{attentions}</div>;
    }

    return null;
  };

  private renderPasswordForm = () => {
    if (this.passwordDialogEnabled() && this.state.password_set) {
      const inputBoxStyle: React.CSSProperties = { fontSize: '16px' };
      return (
        <TextField
          type="password"
          value={this.state.password}
          placeholder={t('activerecord:attributes.user.password')}
          onChange={(e) => this.setState({ password: e.target.value })}
          InputProps={{ style: inputBoxStyle }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && this.generateBtnEnabled()) {
              e.preventDefault();
              this.generateToken();
            }
          }}
        />
      );
    }

    return null;
  };

  private renderDeleteTokenButton = (buttonStyle, buttonLabelStyle) => {
    if (!this.passwordDialogEnabled() || this.state.password_set) {
      return (
        <Button
          key="delete"
          color="secondary"
          onClick={this.onDeleteTokens}
          style={{ ...buttonStyle, position: 'absolute', left: '10px' }}
        >
          <span style={buttonLabelStyle}>
            {t('view:extension.delete_tokens')}
          </span>
        </Button>
      );
    }

    return null;
  };

  private renderPrimaryButton = (buttonStyle, buttonLabelStyle) => {
    if (this.passwordDialogEnabled() && !this.state.password_set) {
      return (
        <Button
          key="set_password"
          color="primary"
          href="/users/password/new"
          style={buttonStyle}
        >
          <span style={buttonLabelStyle}>
            {t('view:extension.set_password_dialog.set_password')}
          </span>
        </Button>
      );
    }

    return (
      <Button
        key="generate"
        color="primary"
        disabled={!this.generateBtnEnabled()}
        onClick={this.generateToken}
        style={buttonStyle}
      >
        <span style={buttonLabelStyle}>
          {t('view:extension.generate_token')}
        </span>
      </Button>
    );
  };

  render() {
    const titleStyle: React.CSSProperties = { padding: '24px 24px 20px' };
    const buttonStyle: React.CSSProperties = { margin: 0, minWidth: '88px' };
    const buttonLabelStyle: React.CSSProperties = { fontSize: '14px' };
    const dialogActionsStyle: React.CSSProperties = { margin: '8px' };
    const explanationStyle: React.CSSProperties = {
      margin: '0 0 26px',
      fontSize: '13px',
    };
    const formTheme = createTheme({
      palette: {
        primary: {
          main: Colors.cyan['500'],
          dark: Colors.cyan['500'],
          light: Colors.cyan['500'],
        },
      },
    });
    const resultStyle: React.CSSProperties = { margin: '36px 0 0' };
    const resultMessageStyle: React.CSSProperties = { marginBottom: '0 0 5px' };
    const failedMessageStyle: React.CSSProperties = {
      color: Colors.red['500'],
    };
    const resultKeyStyle: React.CSSProperties = {
      width: '100px',
      display: 'inline-block',
    };

    return (
      <MuiThemeProvider theme={formTheme}>
        <Dialog open={this.props.open} onClose={this.onCloseDialog}>
          <DialogTitle
            key="title"
            disableTypography
            style={titleStyle}
            children={<h3>{t('view:extension.title')}</h3>}
          />
          <DialogContent>
            <div className="explanation" style={explanationStyle}>
              {this.renderExplanation()}
              {this.renderAttentions()}
            </div>
            <div
              hidden={this.passwordDialogEnabled() && !this.state.password_set}
            >
              <hr />
            </div>
            {this.renderPasswordForm()}
            <div className="result" style={resultStyle}>
              <ul hidden={this.passwordDialogEnabled()}>
                <li>
                  <span style={resultKeyStyle}>Client ID</span>
                  <CopyableText
                    text={this.props.client || null}
                    onCopy={() => this.setState({ is_client_copied: true })}
                  />
                </li>
                <li>
                  <span style={resultKeyStyle}>Token</span>
                  <CopyableText
                    text={this.props.token || null}
                    onCopy={() => this.setState({ is_token_copied: true })}
                  />
                </li>
              </ul>
              <div style={resultMessageStyle}>
                {this.props.successed_to_generate && (
                  <div>
                    {t('view:extension.show_token_dialog.generate_successed')}
                  </div>
                )}
                {this.props.failed_to_generate && (
                  <div style={failedMessageStyle}>
                    {t('view:extension.show_token_dialog.generate_failed')}
                  </div>
                )}
                {this.props.successed_to_delete && (
                  <div>{t('view:extension.delete_successed')}</div>
                )}
                {this.props.failed_to_delete && (
                  <div style={failedMessageStyle}>
                    {t('view:extension.delete_failed')}
                  </div>
                )}
                {this.props.failed_to_generate &&
                  this.props.errors &&
                  this.props.errors.map((error) => (
                    <div style={failedMessageStyle}>{error}</div>
                  ))}
              </div>
            </div>
          </DialogContent>
          <DialogActions
            key="actions"
            children={[
              this.renderDeleteTokenButton(buttonStyle, buttonLabelStyle),
              <Button
                key="cancel"
                children={
                  <span style={buttonLabelStyle}>
                    {t('view:editor.cancel')}
                  </span>
                }
                onClick={this.onClickCancel}
                style={buttonStyle}
              />,
              this.renderPrimaryButton(buttonStyle, buttonLabelStyle),
            ]}
            style={dialogActionsStyle}
          />
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

export default ExtensionDialog;
