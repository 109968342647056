import * as React from 'react';
import qs from 'query-string';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from '@material-ui/core/styles';
import * as Colors from '@material-ui/core/colors';
import * as _ from 'lodash';
import CLLogo from './CLLogo';
import { Root } from '../constants/CommonConstants';
import UserActions from '../actions/UserActions';
import Loading from './Loading';
import { t } from '../i18n';
import { CollapsibleButton } from './CollapsibleButton';

type Horiz = 'left' | 'right';
type Vert = 'top' | 'bottom';
interface Orig {
  horizontal: Horiz;
  vertical: Vert;
}

interface MenuProps {
  open: boolean;
  anchorEl: HTMLElement;
  anchorOrigin: Orig;
  transformOrigin: Orig;
  getContentAnchorEl: null;
  onClose: (e) => void;
  MenuListProps: object;
}

const Menu_ = withStyles({
  paper: {
    boxShadow:
      '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)',
  },
})(Menu);
class MenuEx extends React.Component<MenuProps, {}> {
  static _theme = createTheme({
    overrides: {
      MuiMenuItem: {
        root: { minHeight: '46px' },
      },
      MuiListItem: {
        root: {
          '&$selected': {
            backgroundColor: 'transparent',
          },
          '&$selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
          '&$selected:focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
          '&$focusVisible': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
        button: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
      MuiTouchRipple: {
        rippleVisible: {
          // this is the default opacity value of Material-UI v0's FocusRipple
          opacity: 0.16,
        },
      },
    },
  });

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={MenuEx._theme}>
        <Menu_ {...this.props} />
      </MuiThemeProvider>
    );
  }
}

interface DialogBtnProps {
  children: any; // TODO: fix
  onClick: () => void;
  key: string;
  style: React.CSSProperties;
}

class DialogBtnEx extends React.Component<DialogBtnProps, {}> {
  static _theme = createTheme({
    overrides: {
      MuiButton: {
        root: {
          '&$focusVisible': {
            backgroundColor: 'rgba(153, 153, 153, 0.2)',
          },
        },
      },
      MuiTouchRipple: {
        rippleVisible: {
          // this is the default opacity value of Material-UI v0's CircleRipple
          opacity: 0.1,
        },
      },
    },
  });

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={DialogBtnEx._theme}>
        <Button {...this.props} />
      </MuiThemeProvider>
    );
  }
}

interface Props {
  onChangeOauthDisabled: (state: boolean) => void;
  onFinishOauth: () => void;
  openExtensionDialog: () => void;
  user: {
    name: string;
    state: number;
  };
  dropbox_linked: boolean;
  unlinking: boolean;
  oauthDisabled: boolean;
}
interface State {
  langMenuAnchorEl: HTMLElement;
  userMenuAnchorEl: HTMLElement;
  unlinkDialogOpened: boolean;
  remoteStorageInUnlinkDialog: string;
  // openMenu: boolean;
}
class SignedInHeader extends React.Component<Props, State> {
  private oauthWindow: Window;

  private oauthInterval: number;

  constructor(props) {
    super(props);

    this.state = {
      langMenuAnchorEl: null,
      userMenuAnchorEl: null,
      unlinkDialogOpened: false,
      remoteStorageInUnlinkDialog: '',
    };
  }

  /*
    shouldComponentUpdate(nextProps, nextState) {
      if (this.props.linked === nextProps.linked &&
          this.state.openLang === nextState.openLang &&
          this.state.unlinkDialogOpened === nextState.unlinkDialogOpened//&&
          //this.state.openMenu === nextState.openMenu
      ) {
        return false;
      } else {
        return true;
      }
    }
  */

  render() {
    const dialogButtonLabelStyle = { fontSize: '14px' };
    const unlinkActions = [
      <Button
        children={
          <span
            style={_.defaults(
              { color: Colors.pink.A200 },
              dialogButtonLabelStyle,
            )}
          >
            {t('view:header.cancel')}
          </span>
        }
        onClick={this._onCloseUnlinkDialog}
        key="cancel"
        tabIndex={-1}
        style={{ margin: '0px' }}
      />,
      <DialogBtnEx
        children={
          <span
            style={_.defaults(
              { color: Colors.cyan['500'] },
              dialogButtonLabelStyle,
            )}
          >
            {t('view:header.unlink')}
          </span>
        }
        onClick={this._handleUnlinkRemoteStorage.bind(
          this,
          this.state.remoteStorageInUnlinkDialog,
        )}
        key="unlink"
        style={{ margin: '0px' }}
      />,
    ];
    const headerHeight = '48px';
    const specialShadow =
      '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)';
    const separatorStyle: React.CSSProperties = {
      verticalAlign: 'middle',
      display: 'inline-block',
      borderLeft: '1px solid #eee',
      borderRight: '1px solid #ddd',
      margin: '0 7px',
      height: '100%',
    };
    const buttonLabelStyle: React.CSSProperties = {
      fontSize: '14px',
      textTransform: 'none',
    };
    const iconStyle: React.CSSProperties = {
      fontSize: '24px',
      marginRight: -6,
    };
    const leftIconStyle: React.CSSProperties = {
      fontSize: '16px',
      verticalAlign: 'middle',
    };

    const menuItemStyle: React.CSSProperties = {
      color: 'rgba(0, 0, 0, 0.87)',
      textDecoration: 'unset',
      width: '100%',
    };
    const buttonComponent: React.ElementType = 'button';
    const menuItemProps = {
      component: buttonComponent,
      focusRipple: true,
      style: menuItemStyle,
    };
    const menuLabelStyle: React.CSSProperties = {
      fontSize: '16px',
      fontWeight: 300,
    };

    const spacerStyle: React.CSSProperties = {
      display: 'inline-block',
      width: '32px',
    };
    const menuIconStyle: React.CSSProperties = {
      color: Colors.grey['600'],
      fontSize: '24px',
    };

    const dialogMaxWidth = 48 * 12; // desktopKeylineIncrement * 12

    const remoteStorages = ['dropbox']; // ['dropbox', 'google_drive'];
    const is_linked = (remoteStorage) => this.props[`${remoteStorage}_linked`];
    const getRemoteStorageLabel = (remoteStorage) =>
      `view:header.${remoteStorage}_${is_linked(remoteStorage) ? 'unlink' : 'link'}`;
    const remoteStorageAction = (remoteStorage) =>
      is_linked(remoteStorage)
        ? this._onRequestOpenUnlinkDialog.call(this, remoteStorage)
        : this._openRemoteStorageOauth.call(this, remoteStorage);
    const getRemoteStorageIconClass = (remoteStorage) => {
      switch (remoteStorage) {
        case 'dropbox':
          return 'fa-dropbox';
        default:
          return '';
      }
    };
    const queryParam = qs.parse(location.search);
    const remoteStorageMenuItems = remoteStorages.map((remoteStorage) => (
      <MenuItem
        key={remoteStorage}
        component="button"
        onClick={() => {
          this.setState({ userMenuAnchorEl: null });
          remoteStorageAction(remoteStorage);
        }}
        disabled={this.props.user.state === 0}
        focusRipple
      >
        <span
          style={_.defaults({ fontSize: '24px' }, menuIconStyle)}
          className={`fa ${getRemoteStorageIconClass(remoteStorage)}`}
        />
        <span style={spacerStyle} />
        <span style={menuLabelStyle}>
          {t(getRemoteStorageLabel(remoteStorage))}
        </span>
      </MenuItem>
    ));

    return (
      <AppBar
        style={{
          flexFlow: 'row',
          color: 'gray',
          background: 'white',
          boxShadow: specialShadow,
        }}
        position="relative"
      >
        <div style={{ flexGrow: 0, width: '16px' }} />
        <div
          style={{
            display: 'flex',
            flexGrow: 0,
            flexShrink: 1,
            height: headerHeight,
            minWidth: 0,
            overflow: 'hidden',
          }}
        >
          <CLLogo linkToTop />
        </div>
        <div
          style={{
            display: 'inline-flex',
            flexDirection: 'row',
            flexShrink: 0,
            flexGrow: 1,
            height: headerHeight,
            justifyContent: 'flex-end',
            minWidth: 0,
            maxWidth: '100dvw',
          }}
        >
          <Box sx={{ pr: 1 }}>
            <CollapsibleButton style={{ height: '36px' }} href="/help">
              <Icon style={leftIconStyle}>help</Icon>
              <span style={buttonLabelStyle}>{t('view:header.help')}</span>
            </CollapsibleButton>
            <span style={separatorStyle} />
            <CollapsibleButton
              onClick={(e) =>
                this.setState({ langMenuAnchorEl: e.currentTarget })
              }
            >
              <Icon style={leftIconStyle}>language</Icon>
              <span style={buttonLabelStyle}>
                {t('view:header.language.language')}
              </span>
              <Icon style={iconStyle}>arrow_drop_down</Icon>
            </CollapsibleButton>
            <MenuEx
              open={Boolean(this.state.langMenuAnchorEl)}
              anchorEl={this.state.langMenuAnchorEl}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              getContentAnchorEl={null}
              onClose={(e) => this.setState({ langMenuAnchorEl: null })}
              MenuListProps={{ component: 'div' }}
            >
              {['english', 'japanese'].map((language) => {
                const locale = language.slice(0, 2);
                const isSetLocale = window['locale'] === locale;
                const newQueryParam = {
                  ...queryParam,
                  locale,
                };
                return (
                  <MenuItem
                    {...menuItemProps}
                    key={language}
                    href={`?${qs.stringify(newQueryParam)}`}
                    disabled={isSetLocale}
                    selected={isSetLocale}
                  >
                    <span style={menuLabelStyle}>
                      {t(`view:header.language.${language}`)}
                    </span>
                  </MenuItem>
                );
              })}
            </MenuEx>
            <span style={separatorStyle} />
            <Button
              onClick={(e) =>
                this.setState({ userMenuAnchorEl: e.currentTarget })
              }
            >
              <span style={buttonLabelStyle}>
                {this.props.user ? this.props.user.name : 'loading'}
              </span>
              <Icon style={iconStyle}>arrow_drop_down</Icon>
            </Button>
            <MenuEx
              open={Boolean(this.state.userMenuAnchorEl)}
              anchorEl={this.state.userMenuAnchorEl}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              getContentAnchorEl={null}
              onClose={(e) => this.setState({ userMenuAnchorEl: null })}
              MenuListProps={{ component: 'div' }}
            >
              <MenuItem {...menuItemProps} href={`${Root}/users/edit`} selected>
                <Icon style={menuIconStyle}>settings</Icon>
                <span style={spacerStyle} />
                <span style={menuLabelStyle}>{t('view:header.settings')}</span>
              </MenuItem>
              {remoteStorageMenuItems}
              <MenuItem {...menuItemProps} onClick={this._onClickExtension}>
                <Icon style={menuIconStyle}>extension</Icon>
                <span style={spacerStyle} />
                <span style={menuLabelStyle}>{t('view:header.extension')}</span>
              </MenuItem>
              <MenuItem
                {...menuItemProps}
                data-method="delete"
                href={`${Root}/users/sign_out`}
              >
                <Icon style={menuIconStyle}>exit_to_app</Icon>
                <span style={spacerStyle} />
                <span style={menuLabelStyle}>{t('view:header.sign_out')}</span>
              </MenuItem>
            </MenuEx>
            <Dialog
              onClose={this._onCloseUnlinkDialog}
              open={this.state.unlinkDialogOpened}
              PaperProps={{
                style: { width: '75%', maxWidth: `${dialogMaxWidth}px` },
              }}
            >
              <DialogTitle
                disableTypography
                children={
                  <h3
                    style={{
                      margin: '0px',
                      fontSize: '22px',
                      fontWeight: 400,
                      color: 'rgba(0, 0, 0, 0.87)',
                      lineHeight: '32px',
                    }}
                  >
                    {t(
                      `view:header.${this.state.remoteStorageInUnlinkDialog}_unlink`,
                    )}
                  </h3>
                }
              />
              <DialogContent>
                {this.props.unlinking ? <Loading /> : null}
                <span
                  style={{
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '16px',
                    color: 'rgba(0, 0, 0, 0.6)',
                    lineHeight: 23.0 / 16.0,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `view:header.unlink_${this.state.remoteStorageInUnlinkDialog}_confirmation`,
                    ),
                  }}
                />
              </DialogContent>
              <DialogActions style={{ margin: '8.5px 8px 8px 8px' }}>
                {unlinkActions}
              </DialogActions>
            </Dialog>
          </Box>
        </div>
      </AppBar>
    );
  }

  private _onClickExtension = () => {
    this.setState({ userMenuAnchorEl: null });
    this.props.openExtensionDialog();
  };

  private _onCloseUnlinkDialog = () => {
    this.setState({
      // openMenu: false,
      unlinkDialogOpened: false,
    });
  };

  private _onRequestOpenUnlinkDialog = (remoteStorage) => {
    if (this.props.user.state !== 0) {
      this.setState({
        unlinkDialogOpened: true,
        remoteStorageInUnlinkDialog: remoteStorage,
      });
    }
  };

  private _handleUnlinkRemoteStorage = (remoteStorage) => {
    UserActions.unlinkRemoteStorage(remoteStorage).then(() => {
      this._onCloseUnlinkDialog();
    });
  };

  private _openRemoteStorageOauth = (remoteStorage) => {
    this._openOauth(`/api/${remoteStorage}/oauth`);
  };

  _openOauth(oauthUrl) {
    if (this.props.user.state === 0) return;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

    let left = width / 2 - 400;

    // Fixes dual-screen position
    if (window.screenLeft !== null && window.screenLeft !== undefined) {
      // Most browsers
      left += window.screenLeft;
    } else {
      // Firefox
      left += screen['left']; // this is not standard
    }

    let top = height / 2 - 250;

    // Fixes dual-screen position
    if (window.screenTop !== null && window.screenTop !== undefined) {
      // Most browsers
      top += window.screenTop;
    } else {
      // Firefox
      top += screen['top']; // this is not standard
    }

    const self = this;
    self.oauthWindow = window.open(
      oauthUrl,
      '',
      `status=no,resizable=yes,width=800,height=500,left=${left},top=${top}`,
    );
    self.props.onChangeOauthDisabled(true);
    self.oauthInterval = window.setInterval(() => {
      try {
        const { location } = self.oauthWindow;
        if (
          location.protocol === window.location.protocol &&
          location.host === window.location.host
        ) {
          if (
            self.oauthWindow.document.getElementsByTagName('button').length ===
            0
          ) {
            self.oauthWindow.close();
          }
          // self.setState({ openMenu: false })
        }
      } catch (err) {}
      if (self.oauthWindow.closed) {
        window.clearInterval(self.oauthInterval);
        self.props.onFinishOauth();
        self.props.onChangeOauthDisabled(false);
      }
    }, 1000);
  }

  // private _onUserMenuTouchTap = (e, item) => {
  //  if (!item.ref) return;
  //  $(document.body).append(item.ref);
  //  item.ref[0].click();
  // };
}

export default SignedInHeader;
