import * as React from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { t } from '../../../i18n';

const useStyles = makeStyles((outerTheme) => ({
  group: {
    verticalAlign: 'middle',
  },
  button: {
    fontSize: 14,
    padding: '0px',
    minWidth: '88px',
    color: outerTheme.palette.text.header,
    '&:hover': { color: outerTheme.palette.text.header },
    '&:focus': {
      color: outerTheme.palette.text.header,
      textDecoration: 'none',
    },
  },
  icon: {
    fontSize: '24px',
    boxSizing: 'content-box',
    height: '38px',
    lineHeight: '36px',
    paddingLeft: '10px',
    transitionDuration: '0ms',
  },
  label: {
    fontSize: '14px',
    paddingLeft: '4px',
    paddingRight: '16px',
  },
  menuItem: {
    fontSize: 14,
    minWidth: 128,
    padding: '8px 16px',
  },
  menu: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    lineHeight: 'initial',
  },
}));

export const DownloadZipDropdown = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactElement }
>(({ children }, ref) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        className={classes.group}
        variant="text"
        color="default"
        ref={anchorRef}
        aria-label="split button"
      >
        <Tooltip
          title={
            <span
              style={{
                fontSize: '14px',
                lineHeight: 'normal',
              }}
            >
              {t('view:editor.pdf_tooltip')}
            </span>
          }
        >
          {children}
        </Tooltip>
        <Button
          color="default"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
      </ButtonGroup>
      <Popper
        anchorEl={anchorRef.current}
        open={open}
        transition
        disablePortal
        id="split-button-menu"
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <Paper className={classes.menu}>
              <ClickAwayListener onClickAway={handleClose}>
                <Tooltip
                  title={
                    <span
                      style={{
                        fontSize: '14px',
                        lineHeight: 'normal',
                      }}
                    >
                      {t('view:editor.zip_tooltip')}
                    </span>
                  }
                >
                  <Button
                    href={`${window.location.pathname.replace('/edit', '')}/zip`}
                    target="_blank"
                    className={classes.menuItem}
                    disableRipple
                  >
                    <span>{t('view:projects.download')}</span>
                  </Button>
                </Tooltip>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
});

DownloadZipDropdown.displayName = 'DownloadZipDropdown';
