import { lazy } from 'react';
import { PdfjsBoundary } from '../PdfjsBoundary';

const PdfViewerComponent = lazy(() => import('./PdfViewer'));

export function PdfViewer(props) {
  return (
    <PdfjsBoundary>
      <PdfViewerComponent {...props} />
    </PdfjsBoundary>
  );
}
