import * as React from 'react';
import {
  AppBar,
  Badge,
  Button,
  ButtonProps,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
  styled,
  withTheme,
} from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import {
  MuiThemeProvider,
  withStyles,
  alpha,
  Theme,
  createTheme,
} from '@material-ui/core/styles';
import * as _ from 'lodash';
import { Property } from 'csstype';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { themesList } from 'codemirror/theme';
import RightNav from './RightNav';
import CLLogo from '../../CLLogo';
import { t } from '../../../i18n';
import EditorActions from '../../../actions/EditorActions';
import ErrorDialog from '../ErrorDialog';
import OfflineDialog from '../OfflineDialog';
import * as CL2Types from '../../../CL2Types';

import { FontSizeList as fontSizeList } from './FontSizeList';
import { TeXLiveVersion } from '../../../constants/EditorConstants';
import { RequireEdit } from './permission/RequireEdit';
import { DownloadZipDropdown } from './DownloadZipDropdown';
import { CollapsibleButton } from '../../CollapsibleButton';

function ListItemTextEx(props) {
  return (
    <ListItemText
      primary={<Typography variant="subtitle1" children={props.primary} />}
    />
  );
}

class ButtonEx extends React.Component<ButtonProps, {}> {
  static _theme = (outerTheme) =>
    createTheme(
      {
        overrides: {
          MuiButton: {
            root: {
              color: outerTheme.palette.text.header,
              '&:hover': { color: outerTheme.palette.text.header },
              '&:focus': {
                color: outerTheme.palette.text.header,
                textDecoration: 'none',
              },
              '&$disabled': {
                color: alpha(outerTheme.palette.text.header, 0.3),
              },
            },
          },
        },
      },
      outerTheme,
    );

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={ButtonEx._theme}>
        <Button {...this.props} />
      </MuiThemeProvider>
    );
  }
}

/*
class DropDownMenuMod extends DropDownMenu {
  constructor(props) {
    super(props);
  }

  render() {
    const bkup = this.props;
    this.props = Object.create(Object.getPrototypeOf(this.props));
    Object.assign(this.props, bkup);
    delete this.props.onKeyboardFocus;
    const result = super.render();
    this.props = bkup;
    return result;
  }
}
*/

const BadgeEx = withStyles({
  badge: {
    backgroundColor: Colors.cyan['500'],
    left: '30px',
    top: '0px',
    height: '10px',
    width: '10px',
    transform: 'none',
    minWidth: '0px',
  },
})(Badge);

class ListEx extends React.Component {
  static _theme = (outerTheme) =>
    createTheme(
      {
        overrides: {
          MuiList: {
            root: { color: outerTheme.palette.text.appbar },
          },
          MuiListItem: {
            root: {
              paddingTop: '7px',
              paddingBottom: '7px',
            },
            secondaryAction: { paddingLeft: '16px' },
          },
          MuiListItemSecondaryAction: {
            root: { right: '4px' },
          },
          MuiTypography: {
            subtitle1: {
              fontSize: '16px',
              color: 'currentColor',
              fontWeight: 'unset',
            },
          },
          MuiSwitch: {
            root: {
              right: '24px',
              width: '62px',
              height: '48px',
              padding: '17px 14px',
            },
            thumb: {
              boxShadow:
                'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important',
            },
            switchBase: {
              padding: '14px',
              color: Colors.grey['100'],
              transform: 'translateX(-2px)',
              '&:hover': { backgroundColor: 'transparent' },
              '&$checked': {
                transform: 'translateX(14px)',
                color: Colors.cyan['500'],
                '&:hover': { backgroundColor: 'transparent' },
              },
              '&$checked + $track': {
                backgroundColor: alpha(Colors.cyan['500'], 0.5),
              },
              '&$disabled': {
                color: Colors.grey['300'],
              },
              '&$disabled + $track': {
                backgroundColor: Colors.grey['400'],
              },
            },
            track: {
              backgroundColor: Colors.grey['400'],
              opacity: '1 !important' as Property.Opacity,
            },
          },
        },
      },
      outerTheme,
    );

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={ListEx._theme}>
        <List {...this.props} />
      </MuiThemeProvider>
    );
  }
}

interface SelectProps {
  disabled?: boolean;
  value: string;
  onChange: (e) => void;
  disableUnderline: boolean;
}
class SelectEx extends React.Component<SelectProps, {}> {
  static _theme = (outerTheme) =>
    createTheme(
      {
        overrides: {
          MuiInput: {
            root: {
              fontSize: '15px',
              top: '1px',
              right: '28px',
              color: 'currentColor',
              borderBottom: `1px solid ${Colors.grey['300']}`,
            },
            input: { padding: '5px 0px 4px 12px' },
          },
          MuiSelect: {
            select: {
              paddingRight: '28px',
              '&:focus': { background: 'inherit' },
              '&:-moz-focusring': {
                color: 'currentColor',
                textShadow: 'unset',
              },
            },
          },
          MuiSvgIcon: {
            root: {
              fill: Colors.grey['300'],
              fontSize: '24px',
            },
          },
          MuiList: {
            padding: {
              paddingTop: '16px',
              paddingBottom: '16px',
            },
          },
          MuiMenuItem: {
            root: {
              color: outerTheme.palette.text.header,
              height: '32px',
              minHeight: 0,
            },
          },
          MuiListItem: {
            root: {
              '&$selected': {
                color: Colors.pink.A200,
                backgroundColor: 'rgba(0, 0, 0, 0.14) !important',
              },
              '&$focusVisible, &$focusVisible:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
              },
            },
            button: {
              '&:hover': {
                backgroundColor: outerTheme.palette.action.hover, // 'rgba(0, 0, 0, 0.1)',
              },
            },
          },
        },
      },
      outerTheme,
    );

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={SelectEx._theme}>
        <Select {...this.props} />
      </MuiThemeProvider>
    );
  }
}

function clickEmulateHandler(e) {
  if (e.key !== 'Enter') {
    return;
  }
  e.preventDefault();
  const tgt = e.target;
  if (!('onclick' in tgt)) {
    return;
  }
  const onClickHandler = (tgt as { onclick: any }).onclick;
  typeof onClickHandler === 'function' && onClickHandler(e);
}

interface Props {
  // backgroundColor: string;
  saveHandler: () => void;
  saveAndCompileHandler: () => void;
  killCompileHandler: () => void;
  updateProjectHandler: (param: object) => void; // TODO: use nallower type
  syncHandler: (storageType: number) => void;
  unsyncHandler: (storageType: number) => void;
  openShareDialog: (open: boolean) => void;
  openKeyBindingsDialog: () => void;
  openDevelopmentModeDialog: () => void;
  // syncDisable: boolean;
  saveDisable: boolean;
  compileDisable: boolean;
  project: CL2Types.EditorProject;
  syncing: boolean;
  user: CL2Types.EditorUser;
  result: CL2Types.Result;
  compiling: boolean;
  waiting: boolean;
  network: CL2Types.NetworkStatus;
  error: CL2Types.EditorError;
  theme: Theme;
  className?: string;
  shares?: { token: string; authority: number }[];
}

const SubHeaderDiv = styled('div')(({ theme }) => ({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '20px',
  fontWeight: 500,
  paddingLeft: '16px',
  color: theme.palette.text.headerPrimary,
}));

class EditorHeader extends React.Component<Props, {}> {
  private rightNavRef: {
    rightNav: {
      toggle: () => void;
    };
  } = { rightNav: null };

  constructor(props) {
    super(props);
    this.navbuttonClick = this.navbuttonClick.bind(this);
  }

  private navbuttonClick() {
    const nav = this.rightNavRef.rightNav;
    nav && nav.toggle();
  }

  private onClickShareButton = () => {
    this.props.openShareDialog(true);
  };

  /*
    shouldComponentUpdate(nextProps, nextState) {
      if (this.props.syncDisable == nextProps.syncDisable
        && this.props.saveDisable == nextProps.saveDisable
        && this.props.result == nextProps.result
        && this.props.user == nextProps.user
        && this.props.project == nextProps.project
        && this.props.syncing == nextProps.syncing
        && this.props.waiting == nextProps.waiting
        && this.props.error.open == nextProps.error.open
        && this.props.compiling == nextProps.compiling
        && this.props.compileDisable == nextProps.compileDisable) return false;
      return true;
    }
  */

  render() {
    // let buttonStyle = {backgroundColor: 'transparent', verticalAlign: 'middle', fontSize: '14px'};
    const buttonStyle = {
      backgroundColor: 'transparent',
      padding: '0px',
      minWidth: '88px',
    };
    const buttonStyle2 = _.defaults(
      { width: '36px', minWidth: '36px' },
      buttonStyle,
    );
    const buttonStyle3 = _.defaults({ marginLeft: '150px' }, buttonStyle2);
    // let buttonIconStyle = {boxSizing: 'content-box', height: '100%', verticalAlign: 'middle', lineHeight: '36px', paddingLeft: 10, marginTop: -2, color: 'inherit', transitionDuration: '0ms'};
    const buttonIconStyle: React.CSSProperties = {
      fontSize: '24px',
      boxSizing: 'content-box',
      height: '38px',
      lineHeight: '36px',
      transitionDuration: '0ms',
    };
    const buttonIconStyle2 = _.clone(buttonIconStyle);
    delete buttonIconStyle2.paddingLeft;
    buttonIconStyle2.padding = '0px';
    const buttonLabelStyle = { fontSize: '14px', paddingLeft: '4px' };
    const menuItemLabelStyle = { fontSize: '15px', fontWeight: 300 };
    const separatorStyle: React.CSSProperties = {
      position: 'relative',
      top: '11px',
      display: 'inline-block',
      borderLeft: '1px solid #eee',
      borderRight: '1px solid #ddd',
      height: '32px',
      marginLeft: '15px',
      marginRight: '10px',
    };
    const headerStyle: React.CSSProperties = {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '20px',
      fontWeight: 500,
      margin: '16px 8px',
      borderBottom: '1px solid #cfcfcf',
    };
    const subheaderStyle: React.CSSProperties = {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '20px',
      fontWeight: 500,
      paddingLeft: '16px',
      color: Colors.lightBlue['700'],
    };
    const helpStyle: React.CSSProperties = {
      textAlign: 'left',
      width: 'max-content',
    };
    const compilers = ['platex', 'uplatex', 'lualatex', 'pdflatex', 'xelatex'];
    const bindings = ['default', 'emacs-like', 'vim-like'];
    const devModeCompilers = ['platex', 'uplatex'];
    const isDevModeCompiler =
      this.props.project &&
      devModeCompilers.includes(this.props.project.compiler);
    const enableLaTeXDevMode = Boolean(
      isDevModeCompiler && this.props.project.enable_latex_dev_mode,
    ).toString();
    const latexVersions = [];
    const texLiveVersions = {};

    // 安定版のみの場合は1、開発版も含む場合は2になる
    let latexVersionLength = 1;

    if (isDevModeCompiler) {
      latexVersionLength = 2;
    }

    for (const i of Array.from({ length: latexVersionLength }, (v, j) => j)) {
      const version = TeXLiveVersion + i;
      let devModeEnabled;
      let key;

      if (version === TeXLiveVersion) {
        key = t('view:editor.stable_version');
        devModeEnabled = 'false';
      } else {
        key = t('view:editor.develop_version');
        devModeEnabled = 'true';
      }

      latexVersions.push([key, devModeEnabled]);
      texLiveVersions[devModeEnabled] = `TeXLive ${version}`;
    }
    /*
        const ddmStyles = {
          labelStyle: {height: '48px', lineHeight: '48px'},
          iconStyle: {top: '0px'},
          underlineStyle: {bottom: '10px', margin: '0px 28px 0px 12px'}
        };
    */
    const dropboxSyncDisabled =
      this.props.project && this.props.project.sync_target === 'dropbox'
        ? this.props.syncing || !this.props.user || this.props.user.state === 0
        : // To enable dropbox, use this
          // (this.props.syncing || this.props.user && !this.props.user.dropbox_linked)
          !this.props.user ||
          this.props.user.state === 0 ||
          this.props.syncing ||
          !this.props.user.dropbox_linked;

    //      titleStyle={{height: '48px', lineHeight: '48px'}}
    //      zDepth={0}

    //      iconElementLeft={<span />}
    //      iconStyleRight={{marginTop: 0}}

    //              badgeStyle={{left: '30px', height: '10px', width: this.props.saveDisable ? 0 : '10px'}}>

    //          labelPosition='after'
    //          labelStyle={{paddingLeft: 4}}

    const cmDefaultThemeString = 'cloudlatexDefault';
    const cmDefaultFontSizeString = '14';

    return (
      <AppBar
        elevation={0}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: this.props.theme.palette.background.paper,
          borderBottom: '1px solid lightgray',
          height: '50.5px',
          lineHeight: '48px',
        }}
        className={this.props.className}
        position="relative"
      >
        <div
          style={{
            flexShrink: 1,
            minWidth: 0,
            display: 'flex',
            overflow: 'hidden',
          }}
        >
          <span
            style={{
              marginLeft: 16,
              display: 'inline-block',
              whiteSpace: 'nowrap',
            }}
          >
            <CLLogo linkToTop />
          </span>
        </div>
        <div
          style={{
            flexShrink: 0,
            minWidth: 0,
            maxWidth: '100dvw',
            overflow: 'hidden',
            display: 'inline-flex',
            justifyContent: 'flex-end',
          }}
        >
          <span style={{ whiteSpace: 'nowrap', marginRight: '8px' }}>
            <RequireEdit>
              {(this.props.shares ?? []).filter((share) => share.authority > 0)
                .length ? (
                <Tooltip title={t('view:editor.shared')}>
                  <CollapsibleButton onClick={this.onClickShareButton}>
                    <Icon style={buttonIconStyle}>public</Icon>
                    <span style={buttonLabelStyle}>
                      {t('view:editor.share')}
                    </span>
                  </CollapsibleButton>
                </Tooltip>
              ) : (
                <Tooltip title={t('view:editor.private')}>
                  <CollapsibleButton onClick={this.onClickShareButton}>
                    <Icon style={buttonIconStyle}>lock_outlined</Icon>
                    <span style={buttonLabelStyle}>
                      {t('view:editor.share')}
                    </span>
                  </CollapsibleButton>
                </Tooltip>
              )}
            </RequireEdit>
            <Tooltip title={t('view:editor.pdf_tooltip')}>
              <DownloadZipDropdown>
                <CollapsibleButton
                  disabled={!this.props.result.uri}
                  href={`${window.location.pathname.replace('/edit', '')}/download`}
                  target="_blank"
                >
                  <Icon style={buttonIconStyle}>get_app</Icon>
                  <span style={buttonLabelStyle}>{t('view:editor.pdf')}</span>
                </CollapsibleButton>
              </DownloadZipDropdown>
            </Tooltip>
            <RequireEdit>
              <CollapsibleButton
                onClick={this.props.saveHandler}
                disabled={this.props.saveDisable}
              >
                {(() => {
                  const icon = <Icon style={buttonIconStyle}>save</Icon>;
                  return this.props.saveDisable ? (
                    icon
                  ) : (
                    <BadgeEx
                      color="primary"
                      badgeContent=""
                      variant="dot"
                      overlap="rectangular"
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {icon}
                    </BadgeEx>
                  );
                })()}
                <span style={buttonLabelStyle}>{t('view:editor.save')}</span>
              </CollapsibleButton>
              <Tooltip title={t('view:editor.compile_tooltip')}>
                <span style={{ position: 'relative' }}>
                  {this.props.compiling && (
                    <CollapsibleButton
                      onClick={this.props.killCompileHandler}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        transform: 'translateY(-50%)',
                        paddingTop: 1,
                      }}
                    >
                      <Icon style={buttonIconStyle}>stop</Icon>
                      <span style={buttonLabelStyle}>
                        {t('view:editor.stop')}
                      </span>
                    </CollapsibleButton>
                  )}
                  <CollapsibleButton
                    onClick={this.props.saveAndCompileHandler}
                    disabled={
                      this.props.compileDisable ||
                      this.props.compiling ||
                      this.props.waiting
                    }
                    style={{
                      opacity: this.props.compiling ? 0 : 1,
                      pointerEvents: this.props.compiling ? 'none' : 'auto',
                    }}
                  >
                    <Icon style={buttonIconStyle}>cached</Icon>
                    <span style={buttonLabelStyle}>
                      {t('view:editor.compile')}
                    </span>
                  </CollapsibleButton>
                </span>
              </Tooltip>
              <CollapsibleButton onClick={this.navbuttonClick}>
                <Icon style={buttonIconStyle}>menu</Icon>
                <span style={buttonLabelStyle}>{t('view:editor.menu')}</span>
              </CollapsibleButton>
            </RequireEdit>
            <span style={separatorStyle} />
            <Tooltip
              title={t('view:editor.move_to_my_page')}
              placement="bottom-end"
            >
              <ButtonEx
                href="/projects"
                style={buttonStyle2}
                disableRipple
                aria-label={t('view:editor.move_to_my_page')}
              >
                <Icon style={buttonIconStyle2}>home</Icon>
              </ButtonEx>
            </Tooltip>
            <RightNav rightNavRef={this.rightNavRef}>
              <div style={headerStyle}>
                <span>{t('view:editor.project_settings')}</span>
                <ButtonEx
                  onClick={this.navbuttonClick}
                  style={buttonStyle3}
                  disableRipple
                >
                  <Icon style={buttonIconStyle2}>clear</Icon>
                </ButtonEx>
              </div>
              <SubHeaderDiv>
                <span>{t('view:editor.function')}</span>
              </SubHeaderDiv>
              <ListEx>
                <ListItem>
                  <ListItemTextEx primary={t('view:editor.compiler')} />
                  <ListItemSecondaryAction>
                    <SelectEx
                      disabled={!this.props.project}
                      value={this.props.project && this.props.project.compiler}
                      onChange={this._handleChangeCompiler}
                      disableUnderline
                    >
                      {compilers.map((item) => (
                        <MenuItem
                          value={item}
                          key={item}
                          children={
                            <span style={menuItemLabelStyle}>{item}</span>
                          }
                          onKeyDown={clickEmulateHandler}
                        />
                      ))}
                    </SelectEx>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemTextEx primary={t('view:editor.auto_compile')} />
                  <ListItemSecondaryAction>
                    <Switch
                      checked={
                        this.props.project
                          ? this.props.project.auto_compile
                          : false
                      }
                      onChange={this._handleToggleAutoCompile}
                      color="default"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemTextEx primary={t('view:editor.scroll_sync')} />
                  <ListItemSecondaryAction>
                    <Switch
                      checked={
                        this.props.project
                          ? this.props.project.scroll_sync
                          : false
                      }
                      onChange={this._handleToggleScrollSync}
                      color="default"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemTextEx
                    primary={t('view:editor.enable_spell_check')}
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      checked={
                        this.props.project
                          ? this.props.project.enable_spell_check
                          : false
                      }
                      onChange={this._handleToggleEnableSpellCheck}
                      color="default"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem style={helpStyle}>
                  <ListItemTextEx primary={t('view:editor.key_bind')} />
                  <IconButton
                    style={{
                      display: 'inline',
                      verticalAlign: 'baseline',
                      padding: '0px 12px',
                    }}
                    onClick={this._onClickKeyBindings}
                  >
                    <HelpOutlineIcon fontSize="large" />
                  </IconButton>
                  <ListItemSecondaryAction>
                    <SelectEx
                      disabled={!this.props.project}
                      value={
                        (this.props.project &&
                          bindings.indexOf(this.props.project.key_bindings) >
                            -1 &&
                          this.props.project.key_bindings) ||
                        'default'
                      }
                      onChange={this._handleChangeBinding}
                      disableUnderline
                    >
                      {bindings.map((item) => (
                        <MenuItem
                          value={item}
                          key={item}
                          onKeyDown={clickEmulateHandler}
                        >
                          <span style={menuItemLabelStyle}>{item}</span>
                        </MenuItem>
                      ))}
                    </SelectEx>
                  </ListItemSecondaryAction>
                </ListItem>
              </ListEx>
              <SubHeaderDiv>
                <span>{t('view:editor.display')}</span>
              </SubHeaderDiv>
              <ListEx>
                <ListItem>
                  <ListItemTextEx primary={t('view:editor.display_warnings')} />
                  <ListItemSecondaryAction>
                    <Switch
                      checked={
                        this.props.project &&
                        this.props.project.display_warnings
                      }
                      onChange={this._handleToggleDisplayWarnings}
                      color="default"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemTextEx primary={t('view:editor.editor_themes')} />
                  <ListItemSecondaryAction>
                    <SelectEx
                      value={
                        (this.props.project &&
                          themesList
                            .map((t) => t.name)
                            .includes(this.props.project.editor_theme) &&
                          this.props.project.editor_theme) ||
                        cmDefaultThemeString
                      }
                      onChange={this._handleChangeEditorTheme}
                      disableUnderline
                    >
                      {themesList.map(({ name, dark }) => (
                        <MenuItem
                          value={name}
                          key={name}
                          children={
                            <span style={menuItemLabelStyle}>
                              {name.replace(
                                /([A-Z])/g,
                                (c) => ` ${c.toLowerCase()}`,
                              )}
                            </span>
                          }
                          onKeyDown={clickEmulateHandler}
                        />
                      ))}
                    </SelectEx>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemTextEx primary={t('view:editor.font_size')} />
                  <ListItemSecondaryAction>
                    <SelectEx
                      value={
                        (this.props.project && this.props.project.font_size) ||
                        cmDefaultFontSizeString
                      }
                      onChange={this._handleChangeEditorFontSize}
                      disableUnderline
                    >
                      {fontSizeList.map((item) => (
                        <MenuItem
                          value={item}
                          key={item}
                          children={
                            <span
                              style={menuItemLabelStyle}
                            >{`${item}px`}</span>
                          }
                          onKeyDown={clickEmulateHandler}
                        />
                      ))}
                    </SelectEx>
                  </ListItemSecondaryAction>
                </ListItem>
              </ListEx>
              <SubHeaderDiv>
                <span>{t('view:editor.sync')}</span>
              </SubHeaderDiv>
              <ListEx>
                <ListItem>
                  <ListItemTextEx primary={t('view:editor.dropbox_sync')} />
                  <ListItemSecondaryAction>
                    <div
                      style={
                        dropboxSyncDisabled ? { cursor: 'not-allowed' } : {}
                      }
                    >
                      <Switch
                        checked={
                          this.props.project &&
                          this.props.project.sync_target === 'dropbox'
                        }
                        disabled={dropboxSyncDisabled}
                        onChange={(e, v) =>
                          v
                            ? this.props.syncHandler(0)
                            : this.props.unsyncHandler(0)
                        }
                        color="default"
                      />
                    </div>
                  </ListItemSecondaryAction>
                </ListItem>
              </ListEx>
              <SubHeaderDiv>
                <span>{t('view:editor.latex_version')}</span>
              </SubHeaderDiv>
              <ListEx>
                <ListItem style={helpStyle}>
                  <ListItemTextEx primary={t('view:editor.stable_develop')} />
                  <IconButton
                    style={{ display: 'inline', verticalAlign: 'baseline' }}
                    onClick={this.props.openDevelopmentModeDialog}
                  >
                    <HelpOutlineIcon fontSize="large" />
                  </IconButton>
                  <ListItemSecondaryAction>
                    <SelectEx
                      value={enableLaTeXDevMode}
                      onChange={this._handleToggleEnableDevelopmentMode}
                      disableUnderline
                    >
                      {latexVersions.map((v) => (
                        <MenuItem
                          value={v[1]}
                          key={v[0]}
                          onKeyDown={clickEmulateHandler}
                        >
                          <span style={menuItemLabelStyle}>{v[0]}</span>
                        </MenuItem>
                      ))}
                    </SelectEx>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem style={{ textAlign: 'left', width: 'max-content' }}>
                  <ListItemTextEx primary={t('view:editor.texlive_version')} />
                  <ListItemSecondaryAction>
                    <span
                      style={{
                        paddingRight: '28px',
                        fontSize: '15px',
                        fontWeight: 300,
                      }}
                    >
                      {texLiveVersions[enableLaTeXDevMode]}
                    </span>
                  </ListItemSecondaryAction>
                </ListItem>
              </ListEx>
              <div style={headerStyle}>
                <span>{t('view:editor.link')}</span>
              </div>
              <ListEx>
                <ListItem style={{ paddingLeft: '16px' }}>
                  <a href="/help/" target="_blank" aria-label="help link">
                    <ListItemTextEx primary={t('view:editor.help')} />
                  </a>
                </ListItem>
                <ListItem style={{ paddingLeft: '16px' }}>
                  <a
                    href="/shortcuts/"
                    target="_blank"
                    aria-label="shortcut link"
                  >
                    <ListItemTextEx primary={t('view:editor.shortcut_key')} />
                  </a>
                </ListItem>
              </ListEx>
            </RightNav>
            <ErrorDialog
              onClose={this._onCloseErrorDialog}
              handle_kind={this.props.error.handle_kind}
              open={this.props.error.open}
              message={this.props.error.message}
            />
            <OfflineDialog open={this.props.network === 'offline'} />
          </span>
        </div>
      </AppBar>
    );
  }

  private _onCloseErrorDialog = () => {
    EditorActions.closeErrorDialog();
  };

  private _handleChangeCompiler = (e) => {
    const compiler = e.target.value;
    this.props.updateProjectHandler &&
      this.props.updateProjectHandler({ compiler });
  };

  private _handleChangeBinding = (e) => {
    const key_bindings = e.target.value;
    setTimeout(() => {
      this.props.updateProjectHandler &&
        this.props.updateProjectHandler({ key_bindings });
    }, 0);
  };

  private _handleToggleDisplayWarnings = (e, toggled) => {
    if (this.props.updateProjectHandler) {
      this.props.updateProjectHandler({ display_warnings: toggled });
    }
  };

  private _handleToggleAutoCompile = (e, toggled) => {
    const params = { auto_compile: toggled };
    setTimeout(() => {
      this.props.updateProjectHandler &&
        this.props.updateProjectHandler(params);
    }, 0);
  };

  private _handleChangeEditorTheme = (e) => {
    const editor_theme = e.target.value;
    setTimeout(() => {
      this.props.updateProjectHandler &&
        this.props.updateProjectHandler({ editor_theme });
    }, 0);
  };

  private _handleChangeEditorFontSize = (e) => {
    const font_size = e.target.value;
    setTimeout(() => {
      this.props.updateProjectHandler &&
        this.props.updateProjectHandler({ font_size });
    }, 0);
  };

  private _handleToggleScrollSync = (e, toggled) => {
    if (this.props.updateProjectHandler) {
      this.props.updateProjectHandler &&
        this.props.updateProjectHandler({ scroll_sync: toggled });
    }
  };

  private _handleToggleEnableSpellCheck = (e, toggled) => {
    setTimeout(() => {
      this.props.updateProjectHandler &&
        this.props.updateProjectHandler({ enable_spell_check: toggled });
    }, 0);
  };

  private _handleToggleEnableDevelopmentMode = (e) => {
    setTimeout(() => {
      this.props.updateProjectHandler &&
        this.props.updateProjectHandler({
          enable_latex_dev_mode: JSON.parse(e.target.value),
        });
    }, 0);
  };

  private _onClickKeyBindings = () => {
    // this.setState({ userMenuAnchorEl: null });
    this.props.openKeyBindingsDialog();
  };
}

export default withTheme(EditorHeader);
